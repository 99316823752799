import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                 
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/54/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> Norská receptura Krém na rozpraskané
                    paty
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG-Krem-na-rozpraskane-paty-40ml-box.png"
                      alt="NTG Krem na rozpraskane paty 40ml box"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> Norská receptura Krém na
                      rozpraskané paty
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        Krém intenzivně hydratuje a zklidňuje suché, poškozené a
                        popraskané paty. Unikatní receptura je kombinací
                        pantenolu, který je znám pro své silně zklidňující
                        vlastnosti, a hyaluronátu sodného s glycerinem, které
                        pomáhají obnovit přirozenou hladinu hydratace vaší
                        pokožky. Viditelné zlepšení již po prvním dni používání.
                        Krém vám přináší příjemný pocit, protože vaše pokožka je
                        hebčí a hydratovaná. Tato vyživující receptura
                        nezanechává lepkavé stopy a rychle se vstřebává do
                        pokožky.&nbsp;
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Používejte krém dvakrát denně či častěji na hrubou a
                      popraskanou oblast vašich nohou, dokud nezaznamenáte
                      zlepšení.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>50 ml</dd>
                  </dl>
                </div>
                <div id="fb_17"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
